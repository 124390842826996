/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useReducer, useContext } from 'react'

export const GlobalStateContext = createContext()
export const GlobalDispatchContext = createContext()

export const useGlobalStateContext = () => {
  const context = useContext(GlobalStateContext)
  if (context === undefined) {
    throw new Error(`useCampaignContext must be used within a CampaignProvider`)
  }
  return context
}

export const useGlobalUpdateContext = () => {
  const context = useContext(GlobalDispatchContext)
  if (context === undefined) {
    throw new Error(`GlobalDispatchContext must be used within a CampaignProvider`)
  }
  return context
}

// using react context to access the context bc we need to pass data to the modal component
const initialState = {
  slug: '',
  programDetailUrl: '',
  degreeLevel: '',
}

function reducer(state, action) {
  switch (action.type) {
    case 'SET_SLUG':
      return { ...state, slug: action.payload }
    case 'SET_PROGRAM_DETAIL_URL':
      return { ...state, programDetailUrl: action.payload }
    case 'SET_DEGREE_LEVEL':
      return { ...state, degreeLevel: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action.type}`)
  }
}

const GlobalContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>{children}</GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  )
}
export default GlobalContextProvider
